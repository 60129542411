<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col class="box-center col-12 col-md-10">
        <b-col class="text-center" cols="12">
          <h1>คำนวณภาษี</h1>
          <h2>โปรแกรมคำนวณภาษีหัก ณ ที่จ่ายเงินเดือน (ค่าจ้างแรงงาน)</h2>
        </b-col>

        <div class="tab-container">
          <div
            class="circle-tab"
            v-for="tab in tabs"
            :key="tab"
            :class="{ active: activeTab === tab }"
            @click="changeTab(tab)"
          >
            {{ tab }}
          </div>
          <div
            class="active-indicator"
            :style="{
              transform: `translateX(calc(${(activeTab - 1) * 100}% + 50%))`,
            }"
          ></div>
        </div>

        <div class="tab-content">
          <div
            class="tab-pane"
            v-for="tab in tabs"
            :key="tab"
            :class="{ active: activeTab === tab }"
          >
            <div class="content-wrapper" v-if="tab === 1">
              <div>
                <h1 class="text-colorh4">รายรับ</h1>
              </div>
              <div class="col-12 col-md-6 offset-md-3">
                <div class="input-label">
                  <h6 class="inputText_label" for="salary">เงินเดือน (บาท)</h6>
                </div>
                <!-- <input type="number" class="form-control" id="salary" placeholder="เงินเดือนของคุณเดือนละ" v-model="salary"> -->
                <input
                  type="tel"
                  class="form-control"
                  id="salary"
                  placeholder="เงินเดือนของคุณเดือนละ"
                  v-model="salary"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                />
              </div>
              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <div class="input-label">
                  <h6 class="inputText_label" for="bonus">โบนัส (บาท)</h6>
                </div>
                <input
                  type="tel"
                  class="form-control"
                  id="bonus"
                  placeholder="ระบุโบนัสที่คุณได้รับในปี"
                  v-model="bonus"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                />
              </div>
              <!-- <div class="col-12 col-md-6 offset-md-3 mt-3">
                <div class="input-label">
                  <h6 class="inputText_label" for="otherIncome">
                    รายได้อื่นๆ เช่นการขายของออนไลน์, รับจ้างฟรีแลนซ์ (บาท)
                  </h6>
                </div>
                <input
                  type="tel"
                  class="form-control"
                  id="otherIncome"
                  placeholder="ระบุรายได้ทั้งปี"
                  v-model="otherIncome"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                />
              </div> -->
              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <b-btn
                  v-if="activeTab === 1 && (salary || bonus)"
                  @click="confirmTab1"
                  >ยืนยัน</b-btn
                >
              </div>
              <div
                class="col-12 col-md-6 offset-md-3 mt-3"
                v-if="activeTab === 1 && !(salary || bonus)"
              >
                <p class="text-danger">กรุณากรอกข้อมูลในช่องอย่างน้อย 1 ช่อง</p>
              </div>
            </div>
            <div class="content-wrapper" v-else-if="tab === 2">
              <h1 class="text-colorh4">กองทุนสำรองเลี้ยงชีพ</h1>
              <div
                class="col-12 col-md-6 offset-md-3 mt-3"
                style="
                  margin-left: 18% !important;
                  max-width: 67% !important;
                  margin-bottom: 20px;
                "
              >
                <div class="row">
                  <div class="col-12 col-md-6">
                    <h6 class="inputText_label">ลดหย่อนภาษี:ครอบครัว</h6>
                    <!-- <input type="number" class="form-control" id="retirementFund" v-model="retirementFund"> -->

                    <select
                      class="form-select form-select-lg custom-select"
                      v-model="employmentStatus"
                      placeholder="กรุณาเลือกสถานะ"
                    >
                      <option value="" disabled>กรุณาเลือกสถานะ</option>
                      <option value="employed">โสด</option>
                      <option value="divorce">หย่า</option>
                      <option value="separate">
                        คู่สมรสมีเงินได้(แยกยื่น)
                      </option>
                      <option value="nomoney">คู่สมรสไม่มีเงิน</option>
                    </select>
                  </div>
                  <div class="col-12 col-md-6">
                    <h6 class="inputText_label" for="fundSalary">
                      ลดหย่อนส่วนบุคคล
                    </h6>
                    <input
                      type="tel"
                      class="form-control"
                      id="fundSalary"
                      v-model="fundSalary"
                      :readonly="true"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                    />
                  </div>
                </div>
              </div>

              <div
                v-if="employmentStatus === 'employed'"
                class="col-12 col-md-6 offset-md-3"
                style="margin-left: 18% !important; max-width: 67% !important"
              >
                <div>
                  <p class="p_layout">ลดหย่อนบิดา-มารดา (ตนเอง)</p>
                </div>
                <div class="check_box">
                  <input
                    class="checkbox"
                    type="checkbox"
                    v-model="deductFather"
                  />บิดา
                  <input
                    class="checkbox"
                    type="checkbox"
                    v-model="deductMother"
                  />มารดา
                </div>
                <p class="color-bottom p_layout">
                  คนละ 30,000 บาท (บิดามารดาต้องมีอายุเกิน 60 ปี
                  และมีเงินได้ไม่เกิน 30,000 บาทต่อปี) (ได้ทั้งบิดา
                  มารดาของตนเอง และคู่สมรส)
                </p>

                <p class="p_layout">
                  ลดหย่อนผู้พิการหรือทุพพลภาพ (ไม่มีเงินได้)
                </p>
                <div class="check_box">
                  <input
                    class="checkbox"
                    type="checkbox"
                    v-model="deductDisabledFather"
                  />บิดา
                  <input
                    class="checkbox"
                    type="checkbox"
                    v-model="deductDisabledMother"
                  />มารดา
                  <input
                    class="checkbox"
                    type="checkbox"
                    v-model="deductDisabledRelative"
                  />ญาติ(เช่น พี่,น้อง ฯลฯ)
                </div>
                <p class="color-bottom p_layout">
                  ลดหย่อนผู้พิการหรือทุพพลภาพ (ไม่มีเงินได้)<br />
                  หากเป็นผู้อื่นได้เพียง 1 คนเท่านั้น ลดหย่อนได้คนละ 60,000 บาท
                  (ต้องมีบัตรประจำตัวคนพิการ และไม่มีรายได้)
                </p>
              </div>

              <div
                v-if="employmentStatus === 'divorce'"
                class="col-12 col-md-6 offset-md-3"
                style="margin-left: 18% !important; max-width: 67% !important"
              >
                <div>
                  <p class="p_layout">ลดหย่อนบิดา-มารดา (ตนเอง)</p>
                </div>
                <div class="check_box">
                  <input type="checkbox" v-model="fatherdivorce" />บิดา
                  <input type="checkbox" v-model="motherdivorce" />มารดา
                </div>
                <div>
                  <p class="color-bottom p_layout">
                    คนละ 30,000 บาท (บิดามารดาต้องมีอายุเกิน 60 ปี
                    และมีเงินได้ไม่เกิน 30,000 บาทต่อปี) <br />(ได้ทั้งบิดา
                    มารดาของตนเอง และคู่สมรส)
                  </p>
                </div>
                <div class="p_layout">
                  <p>บุตรคนที่ 1 (เกิดปีใดก็ตาม)</p>
                  <div class="radiobox_radioBox">
                    <input
                      type="radio"
                      name="option1"
                      id="option1"
                      value="มี"
                      v-model="childOption"
                      @change="toggleInput"
                    />
                    <span class="checkmark">
                      <label for="option1"> มี</label>
                    </span>
                    <input
                      type="radio"
                      name="option2"
                      id="option2"
                      value="ไม่มี"
                      v-model="childOption"
                      @change="toggleInput"
                    />
                    <span class="checkmark">
                      <label for="option2"> ไม่มี</label>
                    </span>
                  </div>
                  <div v-show="showInput" class="column-layout">
                    <div class="row">
                      <div class="col-12" style="margin-bottom: 17px">
                        บุตรคนที่ 2 เป็นต้นไป
                      </div>
                      <!-- <p class="p_layout">บุตรคนที่ 2 เป็นต้นไป</p> -->
                      <div class="col-12 col-md-6">
                        <p class="p_layout">จำนวนบุตรที่เกิดก่อนปี 2561</p>
                        <input
                          class="inputText_inputNumber"
                          type="tel"
                          v-model="input1"
                          placeholder="ระบุจำนวนคน"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                        />
                        <p class="color-bottom">ลดหย่อน 30,000 บาท</p>
                      </div>
                      <div class="col-12 col-md-6" style="max-width: 62%">
                        <p class="p_layout">
                          จำนวนบุตรที่เกิดตั้งแต่ปี 2561 เป็นต้นไป
                        </p>
                        <input
                          class="inputText_inputNumber"
                          type="tel"
                          v-model="input2"
                          placeholder="ระบุจำนวนคน"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                        />
                        <p class="color-bottom">ลดหย่อน 60,000 บาท</p>
                      </div>
                    </div>
                  </div>
                </div>

                <p class="p_layout">
                  ลดหย่อนผู้พิการหรือทุพพลภาพ (ไม่มีเงินได้)
                </p>
                <div class="check_box" style="margin-bottom: 22px">
                  <input type="checkbox" v-model="Nofatherdivorce" />บิดา
                  <input type="checkbox" v-model="Nomotherdivorce" />มารดา
                  <input type="checkbox" v-model="Nodivorce" />ญาติ(เช่น
                  พี่,น้อง ฯลฯ)
                </div>
                <p class="color-bottom p_layout">
                  ลดหย่อนผู้พิการหรือทุพพลภาพ (ไม่มีเงินได้)<br />
                  หากเป็นผู้อื่นได้เพียง 1 คนเท่านั้น ลดหย่อนได้คนละ 60,000 บาท
                  (ต้องมีบัตรประจำตัวคนพิการ และไม่มีรายได้)
                </p>
              </div>

              <div
                v-if="employmentStatus === 'separate'"
                class="col-12 col-md-6 offset-md-3"
                style="margin-left: 18% !important; max-width: 67% !important"
              >
                <div>
                  <p class="p_layout">ลดหย่อนบิดา-มารดา (ตนเอง)</p>
                </div>
                <div class="check_box">
                  <input type="checkbox" v-model="Fatherseparate" />บิดา
                  <input type="checkbox" v-model="Motherseparate" />มารดา
                </div>
                <div>
                  <p class="color-bottom p_layout">
                    คนละ 30,000 บาท (บิดามารดาต้องมีอายุเกิน 60 ปี
                    และมีเงินได้ไม่เกิน 30,000 บาทต่อปี) <br />(ได้ทั้งบิดา
                    มารดาของตนเอง และคู่สมรส)
                  </p>
                </div>
                <div class="p_layout">
                  <p>บุตรคนที่ 1 (เกิดปีใดก็ตาม)</p>
                  <div class="radiobox_radioBox">
                    <input
                      type="radio"
                      name="option3"
                      id="option3"
                      value="มี"
                      v-model="childOption"
                      @change="toggleInput"
                    />
                    <label for="option3">มี</label>
                    <input
                      type="radio"
                      name="option4"
                      id="option4"
                      value="ไม่มี"
                      v-model="childOption"
                      @change="toggleInput"
                    />
                    <label for="option4">ไม่มี</label>
                  </div>
                  <div v-show="showInput" class="column-layout">
                    <div class="row">
                      <div class="col-12" style="margin-bottom: 17px">
                        บุตรคนที่ 2 เป็นต้นไป
                      </div>
                      <!-- <p class="p_layout">บุตรคนที่ 2 เป็นต้นไป</p> -->
                      <div class="col-12 col-md-6">
                        <p class="p_layout">จำนวนบุตรที่เกิดก่อนปี 2561</p>
                        <input
                          class="inputText_inputNumber"
                          type="tel"
                          v-model="input3"
                          placeholder="ระบุจำนวนคน"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                        />
                        <p class="color-bottom">ลดหย่อน 30,000 บาท</p>
                      </div>
                      <div class="col-12 col-md-6" style="max-width: 62%">
                        <p class="p_layout">
                          จำนวนบุตรที่เกิดตั้งแต่ปี 2561 เป็นต้นไป
                        </p>
                        <input
                          class="inputText_inputNumber"
                          type="tel"
                          v-model="input4"
                          placeholder="ระบุจำนวนคน"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                        />
                        <p class="color-bottom">ลดหย่อน 60,000 บาท</p>
                      </div>
                    </div>
                  </div>
                </div>

                <p class="p_layout">
                  ลดหย่อนผู้พิการหรือทุพพลภาพ (ไม่มีเงินได้)
                </p>
                <div class="check_box" style="margin-bottom: 22px">
                  <input type="checkbox" v-model="Nofatherseparate" />บิดา
                  <input type="checkbox" v-model="Nomotherseparate" />มารดา
                  <input type="checkbox" v-model="separate" />ญาติ(เช่น พี่,น้อง
                  ฯลฯ)
                </div>

                <p class="p_layout">
                  ลดหย่อนผู้พิการหรือทุพพลภาพ (คู่สมรสไม่มีเงินได้)
                </p>
                <div class="check_box">
                  <input type="checkbox" v-model="deductIncome1" />คู่สมรส
                  <input type="checkbox" v-model="deductIncome2" />บิดา
                  <input type="checkbox" v-model="deductIncome3" />มารดา
                </div>
                <p class="color-bottom p_layout">
                  กรณีบิดา, มารดา, คู่สมรส, บิดาคู่สมรส , มารดาคู่สมรส
                  และบุตรของตนเอง<br />
                  หากเป็นผู้อื่นได้เพียง 1 คนเท่านั้น ลดหย่อนได้คนละ 60,000 บาท
                  (ต้องมีบัตรประจำตัวคนพิการ และไม่มีรายได้)
                </p>
              </div>

              <div
                v-if="employmentStatus === 'nomoney'"
                class="col-12 col-md-6 offset-md-3"
                style="margin-left: 18% !important; max-width: 67% !important"
              >
                <div>
                  <p class="color-bottom p_layout">
                    ลดหย่อนคู่สมรสไม่มีรายได้ 60,000 บาท
                  </p>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p class="p_layout">ลดหย่อนบิดา-มารดา (ตนเอง)</p>
                    <div class="check_box">
                      <input type="checkbox" v-model="deductIncomefather" />บิดา
                      <input
                        type="checkbox"
                        v-model="deductIncomemother"
                      />มารดา
                    </div>
                  </div>
                  <div class="col-12 col-md-6" style="max-width: 62%">
                    <p class="p_layout">ลดหย่อนบิดา-มารดา (คู่สมรส)</p>
                    <div class="check_box">
                      <input
                        type="checkbox"
                        v-model="deductIncomefather1"
                      />บิดา
                      <input
                        type="checkbox"
                        v-model="deductIncomemother2"
                      />มารดา
                    </div>
                  </div>
                </div>
                <div>
                  <p class="color-bottom p_layout">
                    คนละ 30,000 บาท (บิดามารดาต้องมีอายุเกิน 60 ปี
                    และมีเงินได้ไม่เกิน 30,000 บาทต่อปี) <br />(ได้ทั้งบิดา
                    มารดาของตนเอง และคู่สมรส)
                  </p>
                </div>
                <div class="p_layout">
                  <p>บุตรคนที่ 1 (เกิดปีใดก็ตาม)</p>
                  <div class="radiobox_radioBox">
                    <input
                      type="radio"
                      name="option5"
                      id="option5"
                      value="มี"
                      v-model="childOption"
                      @change="toggleInput"
                    />
                    <label for="option5">มี</label>
                    <input
                      type="radio"
                      name="option6"
                      id="option6"
                      value="ไม่มี"
                      v-model="childOption"
                      @change="toggleInput"
                    />
                    <label for="option6">ไม่มี</label>
                  </div>
                  <div v-show="showInput" class="column-layout">
                    <div class="row">
                      <div class="col-12" style="margin-bottom: 17px">
                        บุตรคนที่ 2 เป็นต้นไป
                      </div>
                      <!-- <p class="p_layout">บุตรคนที่ 2 เป็นต้นไป</p> -->
                      <div class="col-12 col-md-6">
                        <p class="p_layout">จำนวนบุตรที่เกิดก่อนปี 2561</p>
                        <input
                          class="inputText_inputNumber"
                          type="tel"
                          v-model="input5"
                          placeholder="ระบุจำนวนคน"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                        />
                        <p class="color-bottom">ลดหย่อน 30,000 บาท</p>
                      </div>
                      <div class="col-12 col-md-6" style="max-width: 62%">
                        <p class="p_layout">
                          จำนวนบุตรที่เกิดตั้งแต่ปี 2561 เป็นต้นไป
                        </p>
                        <input
                          class="inputText_inputNumber"
                          type="tel"
                          v-model="input6"
                          placeholder="ระบุจำนวนคน"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                        />
                        <p class="color-bottom">ลดหย่อน 60,000 บาท</p>
                      </div>
                    </div>
                  </div>
                </div>

                <p class="p_layout">
                  ลดหย่อนผู้พิการหรือทุพพลภาพ (ไม่มีเงินได้)
                </p>
                <div class="check_box" style="margin-bottom: 22px">
                  <input type="checkbox" v-model="deductIncome4" />บิดา
                  <input type="checkbox" v-model="deductIncome5" />มารดา
                  <input type="checkbox" v-model="deductIncome6" />ญาติ(เช่น
                  พี่,น้อง ฯลฯ)
                </div>

                <p class="p_layout">
                  ลดหย่อนผู้พิการหรือทุพพลภาพ (คู่สมรสไม่มีเงินได้)
                </p>
                <div class="check_box">
                  <input type="checkbox" v-model="deductIncome7" />คู่สมรส
                  <input type="checkbox" v-model="deductIncome8" />บิดา
                  <input type="checkbox" v-model="deductIncome9" />มารดา
                </div>
                <p class="color-bottom p_layout">
                  กรณีบิดา, มารดา, คู่สมรส, บิดาคู่สมรส , มารดาคู่สมรส
                  และบุตรของตนเอง<br />
                  หากเป็นผู้อื่นได้เพียง 1 คนเท่านั้น ลดหย่อนได้คนละ 60,000 บาท
                  (ต้องมีบัตรประจำตัวคนพิการ และไม่มีรายได้)
                </p>
              </div>

              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <b-btn
                  variant="secondary"
                  @click="changeTab(1)"
                  class="back-button mr-2"
                  >ย้อนกลับ</b-btn
                >
                <b-btn v-if="activeTab === 2" @click="confirmTab2"
                  >ยืนยัน</b-btn
                >
              </div>
            </div>
            <div class="content-wrapper" v-else-if="tab === 3">
              <h2 class="text-colorh4">
                รายการลดหย่อนภาษี:กองทุนเงินประกันสังคมและที่อยู่อาศัย
              </h2>

              <div></div>
              <div class="col-12 col-md-6 offset-md-3">
                <div class="input-label">
                  <h6 class="inputText_label" for="personalDeduction">
                    ค่าลดหย่อนกองทุนสำรองเลี้ยงชีพ(PVD)
                  </h6>
                </div>
                <input
                  type="tel"
                  class="form-control"
                  id="personalDeduction"
                  placeholder="ระบุจำนวนเงิน"
                  v-model="personalDeduction"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                />
                <div class="input-label">
                  <p class="color-bottom">
                    ไม่เกิน15%ของเงินเดือน(ไม่รวมเงินสมทบจากนายจ้าง)และไม่เกิน500,000บาท
                  </p>
                </div>
              </div>

              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <div class="input-label">
                  <h6 class="inputText_label" for="socialsecurity">
                    เงินประกันสังคม
                  </h6>
                </div>
                <input
                  type="tel"
                  class="form-control"
                  id="socialsecurity"
                  placeholder="ระบุโบนัสที่คุณได้รับในปี"
                  v-model="socialsecurity"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                />
                <div class="input-label">
                  <p class="color-bottom">ไม่เกิน9,000บาท</p>
                </div>
              </div>
              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <div class="input-label">
                  <h6 class="inputText_label" for="housing">
                    ดอกเบี้ยซื้อที่อยู่อาศัย
                  </h6>
                </div>
                <input
                  type="tel"
                  class="form-control"
                  id="housing"
                  placeholder="ระบุรายได้ทั้งปี"
                  v-model="housing"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                />
                <div class="input-label">
                  <p class="color-bottom">ไม่เกิน100,000บาท</p>
                </div>
              </div>
              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <b-btn
                  variant="secondary"
                  @click="changeTab(2)"
                  class="back-button mr-2"
                  >ย้อนกลับ</b-btn
                >
                <b-btn v-if="activeTab === 3" @click="confirmTab3"
                  >ยืนยัน</b-btn
                >
              </div>
            </div>
            <div class="content-wrapper" v-else-if="tab === 4">
              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <h2 class="text-colorh4">รายการลดหย่อนภาษี:ประกัน</h2>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <p for="life">เบี้ยประกันชีวิต</p>
                    <input
                      type="tel"
                      class="form-control"
                      id="life"
                      v-model="life"
                      placeholder="ระบุจำนวนเงิน"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                    />
                    <!-- <input type="number" class="form-control" id="life" v-model="life" @input="handleRetirementFundInput"> -->
                    <p class="color-bottom">ไม่เกิน9,000บาท</p>
                  </div>
                  <div class="col-12 col-md-6">
                    <p for="health">เบี้ยประกันสุขภาพ</p>
                    <input
                      type="tel"
                      class="form-control"
                      id="health"
                      v-model="health"
                      placeholder="ระบุจำนวนเงิน"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                    />
                    <p class="color-bottom">ไม่เกิน9,000บาท</p>
                  </div>
                  <div class="col-12 col-md-6">
                    <p for="healthinsurance">เบี้ยประกันสุขภาพบิดา,มารดา</p>
                    <input
                      type="tel"
                      class="form-control"
                      id="healthinsurance"
                      v-model="healthinsurance"
                      placeholder="ระบุจำนวนเงิน"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                    />
                    <p class="color-bottom">ไม่เกิน9,000บาท</p>
                  </div>
                  <div class="col-12 col-md-6">
                    <p for="pension">เบี้ยประกันชีวิตบำนาญ</p>
                    <input
                      type="tel"
                      class="form-control"
                      id="pension"
                      v-model="pension"
                      placeholder="ระบุจำนวนเงิน"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                    />
                    <p class="color-bottom">ไม่เกิน9,000บาท</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <b-btn
                  variant="secondary"
                  @click="changeTab(3)"
                  class="back-button mr-2"
                  >ย้อนกลับ</b-btn
                >
                <b-btn v-if="activeTab === 4" @click="confirmTab4"
                  >ยืนยัน</b-btn
                >
              </div>
            </div>
            <div class="content-wrapper" v-else-if="tab === 5">
              <h2 class="text-colorh4">รายการลดหย่อนภาษี:กองทุนอื่นๆ</h2>
              <div></div>
              <div class="col-12 col-md-6 offset-md-3">
                <div class="input-label">
                  <h6 class="inputText_label" for="government">
                    กองทุนบำเหน็จบำนาญข้าราชการ(กบข.)
                  </h6>
                </div>
                <input
                  type="tel"
                  class="form-control"
                  id="government"
                  placeholder="ระบุจำนวนเงิน"
                  v-model="government"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                />
                <div class="input-label">
                  <p class="color-bottom">
                    ไม่เกิน15%ของรายได้ทั้งปีและรวมกับกองทุนอื่นไม่เกินไม่เกิน500,000บาท
                  </p>
                </div>
              </div>

              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <div class="input-label">
                  <h6 class="inputText_label" for="savingsfund">
                    กองทุนออมแห่งชาติ(กอช.)
                  </h6>
                </div>
                <input
                  type="tel"
                  class="form-control"
                  id="savingsfund"
                  placeholder="ระบุโบนัสที่คุณได้รับในปี"
                  v-model="savingsfund"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                />
                <div class="input-label">
                  <p class="color-bottom">ไม่เกิน9,000บาท</p>
                </div>
              </div>
              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <div class="input-label">
                  <h6 class="inputText_label" for="teacherfund">
                    กองทุนครูเอกชน
                  </h6>
                </div>
                <input
                  type="tel"
                  class="form-control"
                  id="teacherfund"
                  placeholder="ระบุรายได้ทั้งปี"
                  v-model="teacherfund"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                />
                <div class="input-label">
                  <p class="color-bottom">ไม่เกิน100,000บาท</p>
                </div>
              </div>

              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <b-btn
                  variant="secondary"
                  @click="changeTab(4)"
                  class="back-button mr-2"
                  >ย้อนกลับ</b-btn
                >
                <b-btn v-if="activeTab === 5" @click="calculateTax"
                  >ยืนยัน</b-btn
                >
              </div>
            </div>

            <div class="content-wrapper" v-else-if="tab === 6">
              <h1 class="text-colorh4">คำนวณภาษี</h1>
              <div class="row">
                <div class="column">
                  <h5>รวมเงินรายได้สุทธิ</h5>
                </div>
                <div class="column">
                  <h5>
                    {{
                      totalIncome !== null && totalIncome !== undefined
                        ? totalIncome.toLocaleString(undefined, {
                            style: "decimal",
                            useGrouping: true,
                          })
                        : "N/A"
                    }}
                  </h5>
                </div>

                <div class="column">
                  <h5>บาท</h5>
                </div>
              </div>

              <div class="row">
                <div class="column">
                  <h5>ภาษีที่ต้องจ่าย</h5>
                </div>
                <div class="column">
                  <h5 class="color-tax">
                    {{
                      taxResult !== null && taxResult !== undefined
                        ? taxResult.toLocaleString(undefined, {
                            style: "decimal",
                            useGrouping: true,
                          })
                        : "N/A"
                    }}
                  </h5>
                </div>
                <div class="column">
                  <h5>บาท</h5>
                </div>
              </div>
              <div class="row">
                <div class="column">
                  <h5>หัก ณ ที่จ่ายเดือนละ</h5>
                </div>
                <div class="column">
                  <h5 class="color-tax">
                    {{
                      taxResultDividedByTwelve !== null &&
                      taxResultDividedByTwelve !== undefined
                        ? taxResultDividedByTwelve.toLocaleString(undefined, {
                            style: "decimal",
                            useGrouping: true,
                          })
                        : "N/A"
                    }}
                  </h5>
                </div>
                <div class="column">
                  <h5>บาท</h5>
                </div>
              </div>
              <div class="rectangle-container">
                <div class="rectangle">
                  <p class="color-blog" v-if="showLink">
                    ทั้งนี้ หลังจากกิจการหักภาษี ณ ที่จ่ายแล้ว
                    จะต้องออกหนังสือรับรองการหักภาษี ณ ที่จ่าย (50 ทวิ)
                    ให้กับผู้ที่ถูกหักภาษี ซึ่งสามารถศึกษาเพิ่มเติมได้จากบทความ
                    <a
                      class="color-tax"
                      :href="linkToShow"
                      target="_blank"
                      rel="noopener noreferrer"
                      >“การจัดทำ หนังสือรับรองการหัก ณ ที่จ่าย”
                    </a>
                    หากต้องการคำแนะนำเรื่องภาษี-บัญชี สามารถทักแชทได้ที่
                    <a
                      class="color-tax"
                      href="https://line.me/R/ti/p/@inflowaccount"
                      target="_blank"
                      rel="noopener noreferrer"
                      >@inflowaccount</a
                    >
                    หรือ
                    <a class="color-tax" href="tel:0934142885"
                      >โทร.093-4142885</a
                    >
                    เพราะทุกปัญหาเรื่องภาษี ใกล้ตัวคุณมากกว่าที่คุณรู้
                  </p>
                </div>
              </div>

              <div class="col-12 col-md-6 offset-md-3 mt-3">
                <b-btn
                  variant="secondary"
                  @click="changeTab(5)"
                  class="back-button mr-2"
                  >ย้อนกลับ</b-btn
                >
                <b-btn v-if="activeTab === 6" @click="goBack"
                  >เริ่มทำใหม่</b-btn
                >
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  computed: {
    taxResultDividedByTwelve() {
      return this.taxResult / 12;
    },
  },
  data() {
    return {
      tabs: [1, 2, 3, 4, 5, 6],
      activeTab: 1,
      salary: null,
      bonus: null,
      employmentStatus: "",
      spouseStatus: "",
      sum: null,
      personalDeduction: null,
      fundSalary: 60000,
      socialsecurity: null,
      housing: null,
      life: null,
      health: null,
      pension: null,
      government: null,
      savingsfund: null,
      teacherfund: null,
      healthinsurance: null,
      retirementFund: null,
      taxResult: null,
      showLink: false,
      linkToShow: "",
      totalIncome: null,
      childOption: "",
      showInput: false,
      input1: "",
      input2: "",
      deduction: 0,
      deductFather: false,
      deductMother: false,
      deductDisabledMother: false,
      deductDisabledRelative: false,
      deductDisabledFather: false,
      fatherdivorce: false,
      motherdivorce: false,
      option1: null,
      option2: null,
      Nofatherdivorce: false,
      Nomotherdivorce: false,
      Nodivorce: false,
      Fatherseparate: false,
      Motherseparate: false,
      option3: null,
      option4: null,
      input3: "",
      input4: "",
      Nofatherseparate: false,
      Nomotherseparate: false,
      separate: false,
      deductIncome1: false,
      deductIncome2: false,
      deductIncome3: false,
      deductIncomefather: false,
      deductIncomemother: false,
      deductIncomefather1: false,
      deductIncomemother2: false,
      option5: null,
      option6: null,
      input5: "",
      input6: "",
      deductIncome4: false,
      deductIncome5: false,
      deductIncome6: false,
      deductIncome7: false,
      deductIncome8: false,
      deductIncome9: false,
      defaultRadioValue: null,
      generaldonations: null,
      education: null,
    };
  },
  watch: {
    employmentStatus: function (newStatus, oldStatus) {
      if (newStatus !== oldStatus) {
        this.deductFather = false;
        this.deductMother = false;
        this.deductDisabledFather = false;
        this.deductDisabledMother = false;
        this.deductDisabledRelative = false;
        this.fatherdivorce = false;
        this.motherdivorce = false;
        this.Nofatherdivorce = false;
        this.Nomotherdivorce = false;
        this.Nodivorce = false;
        this.Fatherseparate = false;
        this.Motherseparate = false;
        this.option1 = null;
        this.option2 = null;
        this.option3 = null;
        this.option4 = null;
        this.input3 = "";
        this.input4 = "";
        this.Nofatherseparate = false;
        this.Nomotherseparate = false;
        this.separate = false;
        this.deductIncome1 = false;
        this.deductIncome2 = false;
        this.deductIncome3 = false;
        this.deductIncomefather = false;
        this.deductIncomemother = false;
        this.deductIncomefather1 = false;
        this.deductIncomemother2 = false;
        this.option5 = null;
        this.option6 = null;
        this.input5 = "";
        this.input6 = "";
        this.deductIncome4 = false;
        this.deductIncome5 = false;
        this.deductIncome6 = false;
        this.deductIncome7 = false;
        this.deductIncome8 = false;
        this.deductIncome9 = false;
      }
    },
  },
  methods: {
    calculateNetIncome() {
      const educationDonation = parseFloat(this.education || 0);
      const generalDonations = parseFloat(this.generaldonations || 0);
      const totalDonations = educationDonation + generalDonations;
      const maxDonation = Math.floor(this.totalIncome * 0.1);
      const remainingDonation = maxDonation - educationDonation;

      if (this.totalIncome && totalDonations > maxDonation) {
        alert("รายจ่ายการบริจาคจริงต้องไม่เกิน 10% ของรายได้สุทธิ");
        if (remainingDonation >= generalDonations) {
          this.generaldonations = generalDonations;
        } else {
          this.education = maxDonation;
          this.generaldonations = remainingDonation;
        }
        return;
      }

      this.totalIncome = this.totalIncome - totalDonations;
    },
    calculateTax() {
      const salary = parseFloat(this.salary || 0);
      const bonus = parseFloat(this.bonus || 0);
      const personalDeduction = parseFloat(this.personalDeduction || 0);
      const fundSalary = parseFloat(this.fundSalary || 0);
      const housing = parseFloat(this.housing || 0);
      const socialsecurity = parseFloat(this.socialsecurity || 0);
      const life = parseFloat(this.life || 0);
      const health = parseFloat(this.health || 0);
      const pension = parseFloat(this.pension || 0);
      const government = parseFloat(this.government || 0);
      const savingsfund = parseFloat(this.savingsfund || 0);
      const teacherfund = parseFloat(this.teacherfund || 0);
      const healthinsurance = parseFloat(this.healthinsurance || 0);
      const retirementFund = parseFloat(this.retirementFund || 0);
      const educationDonation =
        this.education !== null &&
        this.education !== undefined &&
        !isNaN(this.education)
          ? parseInt(this.education)
          : 0;
      const generalDonations =
        this.generaldonations !== null &&
        this.generaldonations !== undefined &&
        !isNaN(this.generaldonations)
          ? parseInt(this.generaldonations)
          : 0;
      const totalDonations = educationDonation + generalDonations;
      this.totalIncome -= totalDonations;

      // const educationDonation = parseFloat(this.education || 0);
      // const generalDonations = parseFloat(this.generaldonations || 0);

      const childCountBefore2018divorce = parseInt(this.input2) || 0;
      const childCountAfter2017divorce = parseInt(this.input1) || 0;

      const childCountBefore2018separate = parseInt(this.input4) || 0;
      const childCountAfter2017separate = parseInt(this.input3) || 0;

      const childCountBefore2018nomoney = parseInt(this.input6) || 0;
      const childCountAfter2017nomoney = parseInt(this.input5) || 0;

      const additionalDeductions = [];
      for (let i = 0; i < childCountBefore2018divorce; i++) {
        additionalDeductions.push(30000);
      }
      for (let i = 0; i < childCountAfter2017divorce; i++) {
        additionalDeductions.push(60000);
      }

      const additionalDeductionsnomoney = [];
      for (let i = 0; i < childCountBefore2018nomoney; i++) {
        additionalDeductionsnomoney.push(30000);
      }
      for (let i = 0; i < childCountAfter2017nomoney; i++) {
        additionalDeductionsnomoney.push(60000);
      }

      const additionalDeductionsseparate = [];
      for (let i = 0; i < childCountBefore2018separate; i++) {
        additionalDeductionsseparate.push(30000);
      }
      for (let i = 0; i < childCountAfter2017separate; i++) {
        additionalDeductionsseparate.push(60000);
      }

      const fatherDeduction = this.deductFather ? 30000 : 0;
      const motherDeduction = this.deductMother ? 30000 : 0;
      const fatherdivorce = this.fatherdivorce ? 30000 : 0;

      const motherdivorce = this.motherdivorce ? 30000 : 0;
      const Fatherseparate = this.Fatherseparate ? 30000 : 0;
      const Motherseparate = this.Motherseparate ? 30000 : 0;

      const deductIncomefather = this.deductIncomefather ? 30000 : 0;
      const deductIncomemother = this.deductIncomemother ? 30000 : 0;

      const deductIncomefather1 = this.deductIncomefather1 ? 30000 : 0;
      const deductIncomemother2 = this.deductIncomemother2 ? 30000 : 0;

      const disabledFatherDeduction = this.deductDisabledFather ? 60000 : 0;
      const disabledMotherDeduction = this.deductDisabledMother ? 60000 : 0;
      const disabledRelativeDeduction = this.deductDisabledRelative ? 60000 : 0;

      const Nofatherdivorce = this.Nofatherdivorce ? 60000 : 0;
      const Nomotherdivorce = this.Nomotherdivorce ? 60000 : 0;
      const Nodivorce = this.Nodivorce ? 60000 : 0;

      const Nofatherseparate = this.Nofatherseparate ? 60000 : 0;
      const Nomotherseparate = this.Nomotherseparate ? 60000 : 0;
      const separate = this.separate ? 60000 : 0;

      const deductIncome1 = this.deductIncome1 ? 60000 : 0;
      const deductIncome2 = this.deductIncome2 ? 60000 : 0;
      const deductIncome3 = this.deductIncome3 ? 60000 : 0;

      const deductIncome4 = this.deductIncome4 ? 60000 : 0;
      const deductIncome5 = this.deductIncome5 ? 60000 : 0;
      const deductIncome6 = this.deductIncome6 ? 60000 : 0;

      const deductIncome7 = this.deductIncome7 ? 60000 : 0;
      const deductIncome8 = this.deductIncome8 ? 60000 : 0;
      const deductIncome9 = this.deductIncome9 ? 60000 : 0;
      if (
        !isNaN(salary) &&
        !isNaN(bonus) &&
        !isNaN(personalDeduction) &&
        !isNaN(fundSalary) &&
        !isNaN(housing) &&
        !isNaN(socialsecurity) &&
        !isNaN(life) &&
        !isNaN(health) &&
        !isNaN(pension) &&
        !isNaN(government) &&
        !isNaN(savingsfund) &&
        !isNaN(teacherfund) &&
        !isNaN(healthinsurance) &&
        !isNaN(retirementFund)
      ) {
        const annualIncome = salary * 12 + bonus;

        const monthlyExpense = (salary / 2) * 12;
        const yearlyExpense = monthlyExpense > 100000 ? 100000 : monthlyExpense;

        const totalDeduction =
          fundSalary +
          housing +
          socialsecurity +
          life +
          health +
          pension +
          government +
          savingsfund +
          teacherfund +
          healthinsurance +
          retirementFund +
          personalDeduction +
          fatherDeduction +
          motherDeduction +
          disabledFatherDeduction +
          disabledMotherDeduction +
          disabledRelativeDeduction +
          fatherdivorce +
          motherdivorce +
          Fatherseparate +
          Motherseparate +
          Nofatherdivorce +
          Nomotherdivorce +
          Nodivorce +
          Nofatherseparate +
          Nomotherseparate +
          separate +
          deductIncome1 +
          deductIncome2 +
          deductIncome3 +
          deductIncomefather +
          deductIncomemother +
          deductIncomefather1 +
          deductIncomemother2 +
          deductIncome4 +
          deductIncome5 +
          deductIncome6 +
          deductIncome7 +
          deductIncome8 +
          deductIncome9 +
          additionalDeductions.reduce((a, b) => a + b, 0);
        +additionalDeductionsnomoney.reduce((a, b) => a + b, 0);
        +additionalDeductionsseparate.reduce((a, b) => a + b, 0);
        +disabledRelativeDeduction;

        const taxableIncome = Math.max(
          annualIncome - totalDeduction - yearlyExpense,
          0
        );
        let tax = "ยกเว้นภาษี";

        if (taxableIncome <= 150000) {
          // this.result = 'ยกเว้นภาษี';
        } else if (taxableIncome <= 300000) {
          tax = (taxableIncome - 150000) * 0.05;
          this.result = taxableIncome.toLocaleString(undefined, {
            style: "decimal",
            useGrouping: true,
          });
        } else if (taxableIncome <= 500000) {
          tax = (taxableIncome - 300000) * 0.1 + 7500;
          this.result = taxableIncome.toLocaleString(undefined, {
            style: "decimal",
            useGrouping: true,
          });
        } else if (taxableIncome <= 750000) {
          tax = (taxableIncome - 500000) * 0.15 + 27500;
          this.result = taxableIncome.toLocaleString(undefined, {
            style: "decimal",
            useGrouping: true,
          });
        } else if (taxableIncome <= 1000000) {
          tax = (taxableIncome - 750000) * 0.2 + 65000;
          this.result = taxableIncome.toLocaleString(undefined, {
            style: "decimal",
            useGrouping: true,
          });
        } else if (taxableIncome <= 2000000) {
          tax = (taxableIncome - 1000000) * 0.25 + 115000;
          this.result = taxableIncome.toLocaleString(undefined, {
            style: "decimal",
            useGrouping: true,
          });
        } else if (taxableIncome <= 5000000) {
          tax = (taxableIncome - 2000000) * 0.3 + 365000;
          this.result = taxableIncome.toLocaleString(undefined, {
            style: "decimal",
            useGrouping: true,
          });
        } else {
          tax = (taxableIncome - 5000000) * 0.35 + 1265000;
          this.result = taxableIncome.toLocaleString(undefined, {
            style: "decimal",
            useGrouping: true,
          });
        }

        const totalIncome = annualIncome - totalDeduction - yearlyExpense;

        this.taxResult = tax;
        this.totalIncome = totalIncome >= 0 ? totalIncome : 0;

        this.changeTab(6);

        this.showLink = true;
        this.linkToShow =
          "https://inflowaccount.co.th/withholding-tax-certificate/";
      }
    },
    toggleInput() {
      this.showInput = this.childOption === "มี";
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    confirmTab1() {
      this.changeTab(2);
    },
    confirmTab2() {
      this.changeTab(3);
    },
    confirmTab3() {
      this.changeTab(4);
    },
    confirmTab4() {
      this.changeTab(5);
    },
    confirmTab5() {
      this.changeTab(6);
    },
    goBack() {
      this.tabs = [1, 2, 3, 4, 5, 6];
      this.activeTab = 1;
      this.salary = null;
      this.bonus = null;
      this.employmentStatus = "";
      this.spouseStatus = "";
      this.sum = null;
      this.personalDeduction = null;
      this.fundSalary = 60000;
      this.socialsecurity = null;
      this.housing = null;
      this.life = null;
      this.health = null;
      this.pension = null;
      this.government = null;
      this.savingsfund = null;
      this.teacherfund = null;
      this.healthinsurance = null;
      this.retirementFund = null;
      this.taxResult = null;
      this.showLink = false;
      this.linkToShow = "";
      this.totalIncome = null;
      this.deductFather = false;
      this.deductMother = false;
      this.deductDisabledMother = false;
      this.deductDisabledRelative = false;
      this.deductDisabledFather = false;
      this.fatherdivorce = false;
      this.motherdivorce = false;
      this.option1 = "";
      this.option2 = "";
      this.Nofatherdivorce = false;
      this.Nomotherdivorce = false;
      this.Nodivorce = false;
      this.Fatherseparate = false;
      this.Motherseparate = false;
      this.option3 = "";
      this.option4 = "";
      this.input3 = "";
      this.input4 = "";
      this.Nofatherseparate = false;
      this.Nomotherseparate = false;
      this.separate = false;
      this.deductIncome1 = false;
      this.deductIncome2 = false;
      this.deductIncome3 = false;
      this.deductIncomefather = false;
      this.deductIncomemother = false;
      this.deductIncomefather1 = false;
      this.deductIncomemother2 = false;
      this.option5 = "";
      this.option6 = "";
      this.input5 = "";
      this.input6 = "";
      this.deductIncome4 = false;
      this.deductIncome5 = false;
      this.deductIncome6 = false;
      this.deductIncome7 = false;
      this.deductIncome8 = false;
      this.deductIncome9 = false;
      this.generaldonations = null;
      this.education = null;
    },
  },
};
</script>

<style>
/* @import '~@/assets/css/icomoon.css'; */
@import "@/assets/css/TheHome.css";
</style>
